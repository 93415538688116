<template>
  <div>
    <v-form ref="siteForm">
      <v-row class="d-flex flex-row-reverse mt-3">
        <v-col cols="4">
          <v-btn color="primary" @click.native="saveSite"> Enregistrer</v-btn>
          <v-btn @click.native="archive" class="mx-2"> Archiver</v-btn>
        </v-col>
        <v-col cols="4" sm="4">
          <v-autocomplete
            outlined
            v-model="site_id"
            :items="siteList"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Sélectionner le site"
            dense
            item-text="text"
            item-value="id"
            :rules="[valueSelected]"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { valueSelected, integerValidator } from "@core/utils/validation";
import axios from "@axios";

export default {
  name: "InlineMatching",
  props: {
    id: {
      type: Number,
      mandatory: true,
    },
    identifier: {
      type: String,
      mandatory: true,
    },
  },
  async created() {
    const siteList = await this.$store.dispatch("common/getSiteList");
    this.siteList = siteList.map((site) => ({
      ...site,
      text: `${site["name"]} - ${site["domain"]}`,
    }));
  },
  data() {
    return {
      siteList: [],
      valueSelected,
      integerValidator,
      site_id: -1,
    };
  },
  methods: {
    async saveSite() {
      const isFormValid = this.$refs.siteForm.validate();
      if (!isFormValid) return;
      // const response = await axios.post("/programmatic/match-site", {
      //   site_id: this.site_id,
      //   identifier: this.identifier,
      // });
      const { status } = await axios.post("/partner_sites/match", {
        id: this.id,
        site_id: this.site_id,
      });
      if (status === 200) {
        this.$emit("reload");
      }
    },
    async archive() {
      const { status } = await axios.post(
        "/partner_sites/unmatch-and-archive",
        {
          id: this.id,
        }
      );
      if (status === 200) {
        this.$emit("reload");
      }
    },
  },
};
</script>

<style scoped></style>
