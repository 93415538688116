<template>
  <div>
    <p class="text-2xl mb-6">Sites indéfinis à matcher</p>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              v-model="selectedPartnerId"
              clearable
              :items="partnerList"
              label="Filtrer sur un partenaire"
              item-text="name"
              item-value="id"
              class="select-partner shrink"
              @change="changePartner"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="searchStr"
              label="Rechercher"
              @input="search"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="text-right">
            <v-btn
              v-show="devMode"
              :disabled="!selectedItems.length"
              color="error"
              @click="confirmRemove"
              :loading="removeLoading"
              >Supprimer la sélection</v-btn
            >
          </v-col>
        </v-row>
        <v-data-table
          locale="fr-FR"
          :page="options.page"
          :pageCount="numberOfPages"
          :headers="headers"
          :items="data"
          v-model="selectedItems"
          :show-select="devMode"
          :expanded="expanded"
          :sort-by="'updated'"
          :sort-desc="true"
          :options.sync="options"
          :server-items-length="options.total"
          :loading="loading"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          :items-per-page="options.itemsPerPage"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <a target="_blank">
              <v-btn
                class="mx-2"
                elevation="0"
                fab
                small
                @click="expandRow(item)"
              >
                <v-icon color="primary">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </a>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              <div
                is="InlineMatching"
                :id="item.id"
                :identifier="item.identifier"
                @reload="debouncedUpdateData"
              ></div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiPencil } from "@mdi/js";
import InlineMatching from "@/views/tables/premium/InlineMatching";
import { Konami, KonamiManager } from "@/utils/konami";
import axios from "@axios";
import store from "@/store";
import debounce from "lodash/debounce";

const NOTIF_DISPLAY_DURATION = 3000;

export default {
  name: "SitesMatching",
  components: { InlineMatching },
  data() {
    return {
      devMode: false,
      removeLoading: false,
      partnerList: [],
      selectedPartnerId: null,
      numberOfPages: 0,
      data: [],
      selectedItems: [],
      expanded: [1],
      loading: true,
      searchStr: "",
      searchTimeout: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      dialog: {
        id: -1,
        isOpen: false,
      },
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Partner",
          value: "partner_code",
        },
        {
          text: "Account",
          value: "partner_account_code",
        },
        {
          text: "Identifiant",
          value: "identifier",
        },
        {
          text: "Comment",
          value: "comment",
        },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPencil,
      },
    };
  },
  watch: {
    // options: {handler(){this.debouncedUpdateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.debouncedUpdateData();
    },
    "options.page"() {
      this.debouncedUpdateData();
    },
    "options.sortBy"() {
      this.debouncedUpdateData();
    },
    "options.sortDesc"() {
      this.debouncedUpdateData();
    },
    // "$store.state.c2c.siteList": function () {
    //   this.debouncedUpdateData();
    // },
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    search() {
      const searchStr = this.searchStr.trim();
      const url = new URL(window.location.href);
      if (searchStr != "") {
        url.searchParams.set("search", searchStr);
        window.history.replaceState(null, null, url.toString());
      } else {
        url.searchParams.delete("search");
      }
      window.history.replaceState(null, null, url.toString());
      this.debouncedUpdateData();
    },
    changePartner() {
      const url = new URL(window.location.href);
      const partner = this.partnerList.find(
        (obj) => obj.id === this.selectedPartnerId
      );
      url.searchParams.set("partner", partner.code);
      window.history.replaceState(null, null, url.toString());
      this.debouncedUpdateData();
    },
    async remove() {
      const idListStr = this.selectedItems.map((obj) => obj.id).join(",");
      this.removeLoading = true;
      try {
        const response = await axios.delete(
          `/partner_sites/multiple/${idListStr}`
        );
        this.removeLoading = false;
        if (response.status === 204) {
          await store.dispatch("app/setSnackBar", {
            show: true,
            text: `Les éléments sélectionnés ont été définitivement supprimés.`,
            color: "success",
            timeout: NOTIF_DISPLAY_DURATION,
          });
        } else {
          await store.dispatch("app/setSnackBar", {
            show: true,
            text: `Une erreur est survenue (2) lors de la suppression.`,
            color: "error",
            timeout: NOTIF_DISPLAY_DURATION,
          });
        }
        this.selectedItems = [];
        this.debouncedUpdateData();
      } catch (e) {
        this.removeLoading = false;
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Une erreur est survenue (1) lors de la suppression.`,
          color: "error",
          timeout: NOTIF_DISPLAY_DURATION,
        });
      }
    },
    async confirmRemove() {
      await store.dispatch("app/setDialog", {
        show: true,
        type: "confirm",
        width: 800,
        title: "Suppression",
        text: `Êtes vous certain de vouloir supprimer définitivement: ${this.selectedItems
          .map((obj) => obj.identifier)
          .join(", ")}`,
        code: null,
        callbackFunction: this.remove,
      });
    },
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        partner_id: this.selectedPartnerId,
        search: this.searchStr,
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };

      const { data } = await axios.get("/partner_sites/unmatched", {
        params: queryParams,
      });

      this.loading = false;
      this.data = data.items;
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 30)}...` : input;
    },
  },
  async created() {
    this.partnerList = await this.$store.dispatch("premium/getPartnersList");
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.devMode = true; // d e v
      }),
    ]);
    const urlParams = new URLSearchParams(window.location.search);
    const partnerCode = urlParams.get("partner");
    if (partnerCode) {
      const partner = this.partnerList.find((obj) => obj.code === partnerCode);
      console.log("created partner found from url", partner);
      this.selectedPartnerId = partner.id;
      this.debouncedUpdateData();
    }
    const searchStr = urlParams.get("search");
    if (searchStr) {
      this.searchStr = searchStr;
      this.debouncedUpdateData();
    }
  },
  mounted() {
    this.debouncedUpdateData();
  },
};
</script>

<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>

<style lang="scss" scoped>
.container::v-deep .v-data-table__expanded.v-data-table__expanded__row,
.container::v-deep .v-data-table__expanded.v-data-table__expanded__content {
  background-color: rgb(236, 236, 236) !important;
  color: rgba(94, 86, 105, 0.87) !important;
  border: 20px solid rgba(94, 86, 105, 0.64) !important;
  font-size: 0.75rem;
  font-weight: 600;
}
</style>
